import { Link } from '@lib/features/common';
import { Entry, isEntry } from '@lib/features/entries';
import { useGlobal, useView } from '@lib/store';
import { useNextHydrated } from '@lib/utils/createToggleStore';
import { fmtDateRange, fmtTimeRange } from '@lib/utils/format';
import { VTPerformance } from '@lib/vivaticket/parse/parsePerformanceList';
import { trimSlashes } from '@liquorice/allsorts-craftcms-nextjs';
import { useTranslations } from 'next-intl';
import React from 'react';
import { useIsRTC } from './useSite';

type EventEntryMeta = {
  /** Tickets are sold online */
  isTicketed: boolean;
  /** Included in admission */
  isFree: boolean;
  eventDateFormatted?: string;
  eventTicketString: string;
  eventTimesFormatted?: string[];
  buyTicketsLink?: Link;
  showTicketStr?: boolean;
  priceDescription?: string;
  dateDescription?: string;
  locationDescription?: string;
  eventAk?: string;
  eventUsesPerformances?: boolean;
};

/** Apply further parsing for Event entries */
export const useEvent = <T extends Entry>(
  maybeItem?: T
): Partial<Entry<'event'> & EventEntryMeta> => {
  const t = useTranslations('event');
  const view = useView();
  const eventSettings = useGlobal('eventSettings');
  const bookingUrl = eventSettings?.bookingUrl;
  const vivaticketUrl = eventSettings?.vivaticketUrl;

  /** Avoid date rendering mismatch */
  const hydrated = useNextHydrated();
  const item = maybeItem ? maybeItem : view;

  const isRtc = useIsRTC();
  const showTicketStr = !isRtc;

  if (!isEntry(item, 'event')) return {};

  const { eventMeta, eventDateStart, eventDateEnd, isEverydayEvent } = item;

  const priceDescription = eventMeta?.priceDescription ?? undefined;
  const dateDescription = eventMeta?.dateDescription ?? undefined;
  const locationDescription = eventMeta?.locationDescription ?? undefined;

  const isTicketed = isEntry(item, 'event', 'ticketed');
  const isFree = isTicketed ? false : !!item.isFreeEvent;
  const eventTimes = item?.eventTimes ?? null;

  const eventTimesFormatted =
    eventTimes?.reduce((result, item) => {
      if (!item) return result;

      const { start, end } = item;

      const formatted = fmtTimeRange(start, end, '-', { timeZoneName: 'short' });
      if (formatted) return [...result, formatted];

      return result;
    }, [] as string[]) ?? [];

  let buyTicketsUrl = bookingUrl ?? '';

  let eventAk: string | undefined = undefined;

  let eventUsesPerformances = false;

  if (isTicketed) {
    buyTicketsUrl = `${trimSlashes(vivaticketUrl ?? '')}${item.vtCode}`;
    eventAk = item.eventAk ?? undefined;
    eventUsesPerformances = !!eventAk && !!item.eventUsesPerformances;
    item.vtDescription;
    item.pageTitle = item.pageTitle || item.vtAltName || null;
    item.entrySummary = item.entrySummary || item.vtDescription || null;
  }

  const buyTicketsLink: Link = {
    href: buyTicketsUrl,
    children: isRtc ? t('bookNowCta') : isTicketed ? t('buyTicketsCta') : t('bookCta'),
  };

  const eventDateFormatted = hydrated
    ? isEverydayEvent
      ? t('everyday')
      : fmtDateRange(eventDateStart, eventDateEnd, ' - ')
    : '';

  const eventTicketString = !isFree ? t('ticketed') : t('notTicketed');

  return {
    ...item,
    eventAk,
    isFree,
    isTicketed,
    eventDateFormatted,
    eventTicketString,
    eventTimesFormatted,
    eventUsesPerformances,
    buyTicketsLink,
    showTicketStr,
    priceDescription,
    dateDescription,
    locationDescription,
  };
};

export const useEventPerformances = (eventAk?: string | null) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error | null>(null);
  const [items, setItems] = React.useState<VTPerformance[] | null>(null);

  React.useEffect(() => {
    if (!eventAk) return setLoading(false);
    setLoading(true);
    setError(null);
    fetch(`/api/vivaticket/performances?eventAk=${eventAk}`)
      .then((res) => {
        if (res.ok) {
          res.json().then((data) => {
            setItems(data.items || []);
          });
        }
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventAk]);

  return { loading, error, items };
};
