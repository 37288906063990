// export const fmtArticleLength = (article?: Entry<'article'>) => {
//   const articleReadTime = article?.readTime;
//   return articleReadTime ? `${articleReadTime} min read` : '';
// };

// import { Category } from '@lib/api/fragments/categories';
import { LOCALE, TIMEZONE } from '@lib/constants';
import { Category } from '@lib/features/categories';
import { makeNonNullableArray, toNumber } from '@liquorice/allsorts-craftcms-nextjs';

export const fmtCategoryNames = (
  maybeCats?: MaybeArrayOf<Category>,
  join = ', ',
  exclude?: MaybeArrayOf<Category>
) => {
  const excludeIds = makeNonNullableArray(exclude).map((v) => v.id);
  const cats = makeNonNullableArray(maybeCats).filter((v) => !excludeIds.includes(v.id));

  return cats.map((v) => v.title).join(join);
};

export const fmtDate = (n?: string | number | null) => {
  if (!n) return undefined;
  const date = new Date(n);
  return `${date.getDate()} ${date.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  })}, ${date.getFullYear()}`;
};

export const fmtDateRange = (
  start?: string | number | null,
  end?: string | number | null,
  join = '-'
) => {
  if (!start) return fmtDate(end);
  if (!end) return fmtDate(start);
  console.log('start', start);
  console.log('end', end);
  const startDate = new Date(start);
  const endDate = new Date(end);

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const endMonth = endDate.toLocaleString(LOCALE, {
    month: 'short',
    timeZone: TIMEZONE,
  });
  const startDay = startDate.getDate();
  const endDay = endDate.getDate();

  const endStr = `${endDay} ${endMonth}, ${endYear}`;

  if (startYear === endYear) {
    if (startMonth === endMonth) {
      if (startDay === endDay) return endStr;

      return `${startDay}${join}${endStr}`;
    }
    return `${startDay} ${startMonth} ${join}${endStr}`;
  }
  return `${startDay} ${startMonth}, ${startYear}${join}${endStr}`;
};

export const fmtTime = (value?: string | number | null, options?: Intl.DateTimeFormatOptions) => {
  if (!value) return null;

  const time = new Date(value);

  const formattedTime = time
    .toLocaleString(LOCALE, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: TIMEZONE,
      ...options,
    })
    .replace(' ', '');

  return formattedTime;
};

export const fmtTimeRange = (
  start?: string | number | null,
  end?: string | number | null,
  join = '-',
  options?: Intl.DateTimeFormatOptions
) => {
  if (!start) return fmtTime(end, options);
  if (!end) return fmtTime(start, options);

  return [fmtTime(start, { ...options, timeZoneName: undefined }), fmtTime(end, options)].join(
    join
  );
};

export const fmtPercent = (n?: string | number | null) => {
  return `${toNumber(n)}%`;
};

export const fmtMoney = (n?: string | number | null) => {
  return `$${toNumber(n)}`;
};

export const floatPoint = (n: string | number | null) => {
  return toNumber(parseFloat(`${toNumber(n)}`).toPrecision(12));
};

// export const fmtCategoryCount = (category?: Category) => {
//   if (!category) return '';

//   const { __typeId, relatedEntryCount } = category;

//   const { labels } = getEntryTypeSettings(__typeId) ?? {};
//   const { name = 'Items', singularName = 'Item' } = labels ?? {};

//   return `${relatedEntryCount} ${numberedLabel(relatedEntryCount ?? 0, name, singularName)}`;
// };

export const numberedLabel = (n: number | undefined, name: string, nameSingular: string) =>
  (Math.abs(n ?? 0) === 1 ? nameSingular : name).replace('%n', `${n ?? 0}`);

export const removeOrphan = (str: string, charLim = 10) => {
  const lastIndex = str.lastIndexOf(' ');

  if (lastIndex < 1) return str;

  const before = str.slice(0, lastIndex);
  const after = str.slice(lastIndex + 1);

  if (after.length > charLim) return str;

  return (
    <>
      {before}&nbsp;{after}
    </>
  );
};
